import React from "react"
import Layout from "../components/layout"
import Navigation2 from "../components/navigation2"

import HomeContacto from "../components/home-contacto"
import Hammer from "../images/hammer.jpg"

const IndexPage = () => (
  <Layout>
    <div className="w-full lg:w-10/12 mx-auto">
      <Navigation2 />

      <div className="w-full lg:flex mx-auto">
        <div className="hidden lg:block w-full lg:w-1/3 py-3 px-5 ">
          <img
            src={Hammer}
            alt="CDP Costa Rica"
            className=" w-full object-cover lg:p-5 lg:pt-0"
          />
        </div>
        <div className="w-full lg:w-2/3 px-5 lg:px-0 mt-10 lg:mt-0">
          <h1 className="text-3xl">Concesión de Obra Pública</h1>

          <p className="copy">
            Algunos de nuestros servicios relacionados a las concesiones de obra
            pública son:
          </p>

          <ul className="lists lg:ml-0">
            <li>Preparación de ofertas en licitaciones públicas</li>
            <li>
              Elaboración de estudios de pre-factibilidad y factibilidad en
              proyectos de concesión de iniciativa privada
            </li>
            <li>
              Gestión y representación de intereses de los clientes ante el ente
              concedente
            </li>
            <li>Asesoría integral en fase de ejecución contractual</li>
            <li>
              Asesoramiento preventivo a los clientes para evitar el surgimiento
              de conflictos
            </li>
            <li>Resolución de conflictos</li>
            <li>Opiniones jurídicas</li>
          </ul>
        </div>
      </div>
    </div>

    <HomeContacto />
  </Layout>
)

export default IndexPage
